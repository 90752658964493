import React, { useState, useRef, useEffect, useCallback } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";
import IntegrationTable from "./components/integration-table";
import {
  Loader,
  Notification,
  NotificationType,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";
import { ZeroStateMessage } from "./zero-state-message";
import { AddIntegrationModal } from "../../../components/modals";
import IntegrationSelect from "./components/integration-select";
import "./index.scss";

export const clearIntervals = (intervalRef) => {
  if (intervalRef.current) {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  }
};

export const clearTimeouts = (timeoutId) => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
};

export default function IntegrationsList({ responsiveStyles = {} }) {
  const { integrationService } = container;
  const { getIntegrations } = useStoreActions((store) => store.organization);
  const { currentUser } = useStoreState((store) => store.account);
  const { id, organizationCode } = currentUser;

  const [loading, setLoading] = useState(false);
  const [stateNotification, setStateNotification] = useState(null);
  const [showAddIntegrationModal, setShowAddIntegrationModal] = useState(false);
  const [addIntegrationFailed, setAddIntegrationFailed] = useState(false);
  const [providerDataForNewIntegration, setProviderDataForNewIntegration] =
    useState(null);
  const [connectionId, setConnectionId] = useState(null);
  const [lastRunDate, setLastRunDate] = useState("");
  const [lastRunId, setLastRunId] = useState(null);
  const [authUrl, setAuthUrl] = useState(null);

  const intervalIdRef = useRef();
  const oauthWindowRef = useRef();
  const syncIntervalRef = useRef();
  const checkWindowIsClosedRef = useRef();

  const { response: providersList = [] } = useApiRequest(() => {
    return integrationService.getProviders();
  });

  const {
    loading: connectionListLoading,
    response: connectionList = [],
    sendRequest: requestConnectionList,
  } = useApiRequest(() =>
    integrationService.getConnectionsList(organizationCode)
  );

  const onSetShowIntegration = async (value) => {
    try {
      const providerInfo = await integrationService.getProviderData(value.id);
      setProviderDataForNewIntegration(providerInfo);
      setShowAddIntegrationModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const launchNotification = async (notification) => {
    setStateNotification(notification);
    setTimeout(() => {
      setStateNotification();
    }, 10000);
  };

  const onAddIntegration = async (data, providerId) => {
    setShowAddIntegrationModal(false);
    setLoading(true);
    try {
      const connectionInfo =
        await integrationService.createIntegrationConnection(
          providerId,
          organizationCode,
          id,
          data
        );
      setConnectionId(connectionInfo);

      if (connectionInfo) {
        setAddIntegrationFailed(false);
        if (providerDataForNewIntegration?.authType.includes("oauth")) {
          const authUrl = await integrationService.getAuthUrl(
            providerId,
            connectionInfo
          );
          const left = window.screen.width / 2 - 320;
          const top = window.screen.height / 2 - 300;
          oauthWindowRef.current = window.open(
            authUrl,
            "_blank",
            `width=650, height=600, top=${top}, left=${left}`
          );
          checkWindowClosed();
          setAuthUrl(authUrl);
        } else {
          setLoading(false);
          refresh();
        }
      }
    } catch (error) {
      console.error("ERROR:", error);
      setLoading(false);
      setAddIntegrationFailed(true);
      setShowAddIntegrationModal(true);
    }
  };

  const checkWindowClosed = () => {
    checkWindowIsClosedRef.current = setInterval(() => {
      if (oauthWindowRef.current?.closed) {
        clearInterval(checkWindowIsClosedRef.current);
        onWindowClosed();
      }
    }, 100);
  };

  const onWindowClosed = () => {
    console.log("OAuth window was closed.");
    setAuthUrl(null);
    launchNotification({
      type: NotificationType.error,
      message: `An error occurred while adding new integration. Please try again.`,
      icon: ["fa-solid", "triangle-exclamation"],
    });
    setLoading(false);
  };

  const clearProviderInterval = useCallback(() => {
    setLastRunDate("");
    setLastRunId(null);
    if (syncIntervalRef.current) {
      clearInterval(syncIntervalRef.current);
      syncIntervalRef.current = undefined;
    }
    setLoading(false);
  }, []);

  const checkLastSync = useCallback(async () => {
    try {
      const list = await integrationService.getConnectionsList(
        organizationCode
      );
      const refreshedProvider = list.find(
        (connection) => connection.id === lastRunId
      );
      if (refreshedProvider.lastRunDate !== lastRunDate) {
        if (refreshedProvider.lastRunStatus === "Failed") {
          launchNotification({
            type: NotificationType.error,
            message: `An error occurred during your last update. Please try again.`,
            icon: ["fa-solid", "triangle-exclamation"],
          });
        } else if (refreshedProvider.lastRunStatus === "Success") {
          launchNotification({
            type: NotificationType.success,
            message: `Your integration has been updated.`,
            icon: ["fa-solid", "circle-check"],
          });
        }
        clearProviderInterval();
      }
    } catch {
      clearProviderInterval();
    }
  }, [lastRunDate, lastRunId, organizationCode, clearProviderInterval]);

  useEffect(() => {
    if (lastRunDate && lastRunId && !syncIntervalRef.current) {
      syncIntervalRef.current = setInterval(checkLastSync, 5000);

      const timeoutId = setTimeout(() => {
        clearIntervals(syncIntervalRef.current);
      }, 90000);

      return () => {
        clearIntervals(syncIntervalRef.current);
        clearTimeouts(timeoutId);
      };
    }
  }, [lastRunDate, lastRunId, checkLastSync]);

  useEffect(() => {
    const clearCheckStatusInterval = () => clearIntervals(intervalIdRef);

    if (authUrl) {
      intervalIdRef.current = setInterval(checkOauthStatus, 1500);
    } else {
      clearCheckStatusInterval();
    }
    return clearCheckStatusInterval;
  }, [authUrl]);

  const checkOauthStatus = async () => {
    try {
      const status = await integrationService.getConnectionStatus(connectionId);
      if (status === "active") {
        setAuthUrl(null);
        launchNotification({
          type: NotificationType.success,
          message: `Your ${providerDataForNewIntegration.name} account has successfully connected with Transfr. Please wait a few minutes while we import your remaining data.`,
          icon: ["fa-solid", "circle-check"],
        });
        requestConnectionList();
        getIntegrations({ orgCode: organizationCode });
        setLoading(false);
        if (oauthWindowRef.current) {
          oauthWindowRef.current.close();
          oauthWindowRef.current = undefined;
        }
        clearInterval(intervalIdRef.current);
      } else if (status === "failed") {
        setAuthUrl(null);
        launchNotification({
          type: NotificationType.error,
          message: `An error occurred while adding new integration. Please try again.`,
          icon: ["fa-solid", "triangle-exclamation"],
        });
        setLoading(false);
        if (oauthWindowRef.current) {
          oauthWindowRef.current.close();
          oauthWindowRef.current = undefined;
        }
        clearInterval(intervalIdRef.current);
      } else if (status === "pending") {
        return;
      }
    } catch (error) {
      console.error("ERROR:", error);
      setLoading(false);
      clearInterval(intervalIdRef.current);
    }
  };

  const handleAddIntegrationCancel = () => {
    setShowAddIntegrationModal(false);
    setProviderDataForNewIntegration(null);
  };

  const pageHeader = (
    <PageHeader
      title={"Integrations"}
      badge={connectionList?.length || "0"}
      subTitle={"Quickly pull in data from third party tools."}
    >
      <TextTooltip
        text="Create Integration"
        className="integration-select-tooltip"
        theme="dropdown"
        position="bottom-end"
      >
        <IntegrationSelect
          providers={providersList}
          onSelectProvider={onSetShowIntegration}
          icon={["fa-regular", "plus"]}
        />
      </TextTooltip>
    </PageHeader>
  );

  if (loading || connectionListLoading) {
    return (
      <PageLayout
        className="integrations-list-page"
        responsiveStyles={responsiveStyles}
        header={pageHeader}
      >
        {(loading || connectionListLoading) && <Loader overlay />}
      </PageLayout>
    );
  }

  const refresh = async (lastRunDate, lastRunId) => {
    if (lastRunDate && lastRunId) {
      setLastRunDate(lastRunDate);
      setLastRunId(lastRunId);
      setLoading(true);
    }
    requestConnectionList();
    getIntegrations({ orgCode: organizationCode });
  };

  return (
    <PageLayout
      className="integrations-list-page"
      responsiveStyles={responsiveStyles}
      header={pageHeader}
    >
      <div className="integrations-table-container">
        {!!connectionList && connectionList.length > 0 ? (
          <IntegrationTable
            connectionList={connectionList}
            launchNotification={launchNotification}
            refresh={refresh}
            isLoading={loading}
          />
        ) : (
          <ZeroStateMessage
            providers={providersList}
            onActionClicked={onSetShowIntegration}
          />
        )}
      </div>
      <div className="notification-container success-notification">
        {stateNotification && (
          <Notification
            type={stateNotification.type}
            icon={stateNotification.icon}
            onClose={() => setStateNotification(null)}
            closable
          >
            {stateNotification.message}
          </Notification>
        )}
      </div>
      {showAddIntegrationModal && (
        <AddIntegrationModal
          onCancel={handleAddIntegrationCancel}
          open={showAddIntegrationModal}
          onAddIntegration={onAddIntegration}
          integration={providerDataForNewIntegration}
          addIntegrationFailed={addIntegrationFailed}
        />
      )}
    </PageLayout>
  );
}
